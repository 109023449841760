<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "@/assets/styles/index.scss";

export default {
  name: "App",
};
</script>
