<template>
    <div class="right-pannel-container">
        <div class="title d-flex align-center px-4 py-2">
            <v-row class="align-center">
                <v-col cols="3" class="d-flex justify-center align-center">
                    <v-icon class="icon" size="small">mdi-bell-outline</v-icon>
                </v-col>
                <v-col cols="3" class="d-flex justify-center align-center">
                    <v-icon class="icon" size="small">mdi-book-open-outline</v-icon>
                </v-col>
                <v-col cols="3" class="d-flex justify-center align-center">
                    <v-icon class="icon" size="small">mdi-calendar</v-icon>
                </v-col>
                <v-col cols="3" class="d-flex justify-center align-center">
                    <v-icon class="icon" size="small">mdi-cast</v-icon>
                </v-col>
            </v-row>
        </div>
        <v-divider />
        <div fluid>
            <div class="d-flex flex-column justify-center align-center pa-4">
                <div class="right-pannel-profile-image">
                    <div class="image"></div>
                </div>
                <div class="name pt-4 bold-600 font-20">Alin Gavriliuc</div>
                <div class="account bold-300">@alingavriliuc</div>
            </div>
        </div>
        <div class="pt-4 px-4 members-list-container">
            <div class="d-flex justify-space-between mb-4">
                <span class="bold-400 opacity-70">Top Contributors</span>
                <span class="bold-300 see-all-button pointer opacity-60">see all</span>
            </div>
            <div class="members-list">
                <div v-for="(member, index) of members" :key="index">
                    <div class="member-card d-flex align-center px-4">
                        <img :src="member.image" alt="">
                        <div class="d-flex flex-column pl-4">
                            <div class="font-18 bold-600">{{ member.name }}</div>
                            <div class="font-14 bold-300">{{ member.time }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RightPannel',

    data() {
        return {
            members: [
                {
                    image: "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
                    name: "Antony",
                    time: "2 hours ago"
                },
                {
                    image: "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Edward",
                    time: "5 min ago"
                },
                {
                    image: "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Mark",
                    time: "5 hours ago"
                },
                {
                    image: "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
                    name: "Ella",
                    time: "1 day ago"
                },
                {
                    image: "https://images.pexels.com/photos/2672979/pexels-photo-2672979.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Frank",
                    time: "4 hours ago"
                },
                {
                    image: "https://images.pexels.com/photos/813940/pexels-photo-813940.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Ron",
                    time: "2 hours ago"
                },
                {
                    image: "https://images.pexels.com/photos/2043590/pexels-photo-2043590.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Alice",
                    time: "1 hour ago"
                },
                {
                    image: "https://images.pexels.com/photos/2690323/pexels-photo-2690323.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Verra",
                    time: "2 hours ago"
                },
                {
                    image: "https://images.pexels.com/photos/2923156/pexels-photo-2923156.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Elon",
                    time: "2 days ago"
                },
                {
                    image: "https://images.pexels.com/photos/1192609/pexels-photo-1192609.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Andrew .A",
                    time: "now"
                },
                {
                    image: "https://images.pexels.com/photos/573298/pexels-photo-573298.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "Gaby",
                    time: "2 min ago"
                },
                {
                    image: "https://images.pexels.com/photos/175696/pexels-photo-175696.jpeg?auto=compress&cs=tinysrgb&w=1600",
                    name: "George",
                    time: "5 hours ago"
                },
            ]
        };
    },
}
</script>


<style lang="scss">
.right-pannel-container {
    color: white;
    max-height: 100vh;

    .v-divider {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .title {
        height: 50px;
    }

    .icon {
        opacity: 0.7;
        transition: 250ms all ease-in-out;
        cursor: pointer;
        position: relative;
        z-index: 1 !important;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.2);
            transition: 250ms all ease-in-out;
            z-index: -1;
            opacity: 0;
        }

        &:hover {
            border-radius: 30px;

            &::after {
                width: 180%;
                height: 180%;
                opacity: 1;
            }
        }
    }

    .right-pannel-profile-image {
        margin-top: 50px;
        width: 100px;
        height: 100px;
        position: relative;

        .image {
            z-index: 2;
            object-fit: cover;
            background-image: url("@/assets/images/profile_photo.jpg");
            background-size: 110%;
            background-position: center;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            position: absolute;
            border: 3px solid rgba(255, 255, 255, 0.3);
        }

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: rgba(255, 255, 255, 0.2);
            transition: 250ms all ease-in-out;
            animation: rotate 2s linear infinite;
            background: linear-gradient(-45deg, #f492f0 0%, #f9c58d 100%);
            transform: translate3d(0px, 20px, 0) scale(0.95);
            filter: blur(20px);
            opacity: 0.6;
            z-index: 0;
        }
    }

    .members-list-container {
        .members-list {
            height: 66vh !important;
            min-height: 300px;
            overflow: scroll;

            .member-card {
                margin-bottom: 10px;
                height: 70px;
                border-radius: 5px;
                background-color: #414668;
                transition: 250ms all ease-in-out;
                box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
                cursor: pointer;

                img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                }

                &:hover {
                    background-color: #4a4f6a;
                }
            }
        }

    }


    @keyframes rotate {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
</style>