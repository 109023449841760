<template>
  <div class="hero-container">
    <v-container class="mb-5">
      <span class="text-5xl sm:text-8xl font-bold opacity-90">WEB & MOBILE DEVELOPER</span>
      <v-row class="pt-4">
        <v-col cols="12" sm="6">
          <span>
            Experienced web and mobile developer with a passion for crafting intuitive and engaging user experiences. Adept at working in fast-paced, collaborative environments, and leveraging agile
            methodologies to drive successful project outcomes.
          </span>
        </v-col>
        <v-col class="flex justify-center align-center">
          <v-btn variant="outlined" @click="mailto"> get in touch </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="profile-img-container overflow-hidden mt-10">
      <div class="profile-image">
        <div class="relative w-100 h-100 flex justify-center">
          <img :src="require('@/assets/images/profile.png')" alt="profile-image" class="ag-profile-img" />
          <div class="badge-left skill-badge badge-vue-js"><img :src="require('@/assets/icons/vue-js.png')" alt="" /></div>
          <div class="badge-left skill-badge badge-flutter"><img :src="require('@/assets/icons/flutter.png')" alt="" /></div>
          <div class="badge-left skill-badge badge-python"><img :src="require('@/assets/icons/python.png')" alt="" /></div>
          <div class="badge-left skill-badge badge-node"><img :src="require('@/assets/icons/node-js.png')" alt="" /></div>
          <div class="badge-left skill-badge badge-mongodb"><img :src="require('@/assets/icons/mongodb.png')" alt="" /></div>
          <div class="badge-left skill-badge badge-firebase"><img :src="require('@/assets/icons/firebase.png')" alt="" /></div>

          <div class="badge-right skill-badge badge-git"><img :src="require('@/assets/icons/git.png')" alt="" /></div>
          <div class="badge-right skill-badge badge-figma"><img :src="require('@/assets/icons/figma.png')" alt="" /></div>
          <div class="badge-right skill-badge badge-react"><img :src="require('@/assets/icons/react.png')" alt="" /></div>
          <div class="badge-right skill-badge badge-css"><img :src="require('@/assets/icons/css.png')" alt="" /></div>
          <div class="badge-right skill-badge badge-html"><img :src="require('@/assets/icons/html.png')" alt="" /></div>
          <div class="badge-right skill-badge badge-java-script"><img :src="require('@/assets/icons/java-script.png')" alt="" /></div>
        </div>
      </div>
    </div>
    <v-container>
      <v-row>
        <v-col><v-icon icon="mdi-compass" class="me-2 p-0 compas-icon w-auto"></v-icon><span>44.836151, -0.580816</span></v-col>
        <v-col class="flex justify-end">France, Bordeaux</v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  name: "HeroComponent",

  data: () => ({}),

  methods: {
    mailto() {
      window.open("mailto:alingavriliuc@hotmail.com");
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.create({
      trigger: ".hero-container",
      start: "bottom 50%",
      end: "bottom 50%",
      onUpdate: () => {
        gsap.to(".skill-badge", {
          duration: 1,
          opacity: 0,
          ease: "power4.out",
        });
        gsap.to(".badge-left", {
          duration: 1,
          x: -500,
          ease: "power4.out",
          stagger: {
            each: 0.1,
          },
        });
        gsap.to(".badge-right", {
          duration: 1,
          x: 500,
          ease: "power4.out",
          stagger: {
            each: 0.1,
          },
        });
      },
      onEnterBack: () => {
        gsap.to(".skill-badge", {
          duration: 1,
          opacity: 1,
          ease: "power4.out",
        });
        gsap.to(".badge-left", {
          duration: 1,
          x: 0,
          ease: "power4.out",
          stagger: {
            each: 0.1,
          },
        });
        gsap.to(".badge-right", {
          duration: 1,
          x: 0,
          ease: "power4.out",
          stagger: {
            each: 0.1,
          },
        });
      },
    });
  },
};
</script>

<style lang="scss">
.hero-container {
  height: 95vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .compas-icon {
    -webkit-animation: location-rotate 2s ease-in-out;
    -webkit-animation-iteration-count: infinite;
  }

  @keyframes location-rotate {
    0% {
      transform: translate(0, -2px) rotate(0deg);
    }
    75% {
      transform: translate(0, -2px) rotate(360deg);
    }
    100% {
      transform: translate(0, -2px) rotate(360deg);
    }
  }
}

.profile-img-container {
  background-color: rgba($color: #fff, $alpha: 0.8);
  position: relative;
  height: 500px;
  width: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: lighten;
  background-image: url("@/assets/patterns/doodad.png");

  .profile-image {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0;

    .ag-profile-img {
      height: 100%;
      filter: grayscale(1);
      z-index: 3;
    }

    .skill-badge {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      overflow: hidden;
      background-color: #1c1d20;
      padding: 20px;
      z-index: 2;
      border: 2px solid white;
    }

    .badge-node {
      position: absolute;
      top: 10%;
      left: 10%;
      box-shadow: 0 0 10px 0 green;
    }

    .badge-mongodb {
      position: absolute;
      top: 40%;
      left: 10%;
      box-shadow: 0 0 10px 0 green;
    }

    .badge-firebase {
      position: absolute;
      bottom: 10%;
      left: 10%;
      box-shadow: 0 0 10px 0 yellow;
    }

    .badge-python {
      position: absolute;
      bottom: 10%;
      left: 25%;
      box-shadow: 0 0 10px 0 #3873a3;
    }

    .badge-vue-js {
      position: absolute;
      top: 10%;
      left: 25%;
      box-shadow: 0 0 10px 0 green;
    }

    .badge-flutter {
      position: absolute;
      top: 40%;
      left: 25%;
      box-shadow: 0 0 10px 0 #3fc4ff;
    }

    .badge-css {
      position: absolute;
      top: 10%;
      right: 10%;
      box-shadow: 0 0 10px 0 #004ce8;
    }

    .badge-html {
      position: absolute;
      top: 40%;
      right: 10%;
      box-shadow: 0 0 10px 0 #e34c26;
    }

    .badge-java-script {
      position: absolute;
      bottom: 10%;
      right: 10%;
      box-shadow: 0 0 10px 0 #f0db4f;
    }

    .badge-react {
      position: absolute;
      bottom: 10%;
      right: 25%;
      box-shadow: 0 0 10px 0 #61dafb;
    }

    .badge-git {
      position: absolute;
      top: 10%;
      right: 25%;
      box-shadow: 0 0 10px 0 #f14e32;
    }

    .badge-figma {
      position: absolute;
      top: 40%;
      right: 25%;
      box-shadow: 0 0 10px 0 #f24e1e;
    }
  }
}
</style>
