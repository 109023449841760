<template>
    <a href="/projects/explorer" target="_blank" rel="noopener noreferrer">
	<div class="discover-explorer-section pointer w-100 mt-10 overflow-hidden relative">
		<div class="w-100 h-100 discover-explorer-background absolute blur-md -z-50" :style="`background-image: url(${require('@/assets/images/explorer/explorer-bkg-featured.jpeg')})`"></div>
		<div class="w-100 h-100 relative">
            <v-container class="z-50 w-100 h-100">
                <v-row class="align-center w-100 h-100 m-0">
                    <v-col cols="12" md="6" class="flex justify-center">
                        <div class="discover-main-image w-96 h-60 overflow-hidden" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    </v-col>
                    <v-col cols="12" md="6" class="flex flex-col align-center justify-center">
                        <span class="text-3xl font-bold mb-4 uppercase text-center">Explorer project demo</span>
                        <v-btn variant="outlined" class="border-2 font-black backdrop-blur text-2xl">explore</v-btn>
                    </v-col>
                </v-row>
            </v-container>

            <div class="rotate-12 absolute -top-40 opacity-100 flex flex-col -z-10">
                <div class="top-row -top-20 flex w-100 gap-5">
                    <div class="discover-main-image opacity-0 discover-left discover-image-1 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-left discover-image-2 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-left discover-image-3 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-left discover-image-4 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-left discover-image-5 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                </div>

                <div class="center-row top-20 flex w-100 gap-5">
                    <div class="discover-main-image opacity-0 discover-right discover-image-1 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-right discover-image-2 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-right discover-image-3 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-right discover-image-4 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-right discover-image-5 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                </div>

                <div class="bottom-row -bottom-32 flex w-100">
                    <div class="discover-main-image opacity-0 discover-left discover-image-1 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-left discover-image-2 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-left discover-image-3 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-left discover-image-4 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                    <div class="discover-main-image opacity-0 discover-left discover-image-5 w-96 h-60 blur-sm" :style="`background-image: url(${require('@/assets/images/explorer-screen.jpg')})`"></div>
                </div>
            </div>
		</div>
	</div>
</a>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
	name: "DiscoverExplorer",

	mounted() {
		ScrollTrigger.create({
			trigger: ".works-container-wrapper",
			start: "top 50",
			end: "bottom 50",
            once: true,
			onEnter: () => {
				gsap.from(".top-row", {
					duration: 2,
					xPercent: -100,
                    opacity: 1,
					ease: "power4.out",
					stagger: {
						each: 0.1,
					},
				});
                gsap.from(".center-row", {
                    duration: 2,
                    xPercent: 100,
                    opacity: 1,
                    ease: "power4.out",
                    stagger: {
                        each: 0.1,
                    },
                });
				gsap.from(".bottom-row", {
					duration: 2,
					xPercent: -100,
                    opacity: 1,
					ease: "power4.out",
					stagger: {
						each: 0.1,
					},
				});
                gsap.to(".discover-main-image", {
                    duration: 2,
                    opacity: 1,
                    ease: "power4.out",
                    stagger: {
                        each: 0.1,
                    },
                });
			},
		});
	},
};
</script>

<style lang="scss">
.discover-explorer-section {
	color: #fff;
	overflow: hidden;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	.discover-explorer-background {
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.discover-main-image {
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

}
</style>
