<template>
    <div class="bottom-pane-container">
        <div class="left-side"></div>
        <div class="central"><KeyboardVue/></div>
        <div class="right-side"></div>
    </div>
</template>

<script>
import KeyboardVue from './Keyboard.vue';

export default {
    name: 'BottomPaneVue',

    components: {
        KeyboardVue,
    },
}
</script>

<style lang="scss">
    .bottom-pane-container {
        display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            "left-side central right-side";
        height: 100%;
        width: 100%;
    }
</style>