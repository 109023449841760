<template>
    <div class="main-section-card pointer" :style="`background-image: url(${require('@/assets/images/explorer/explorer-bkg-featured.jpeg')})`">
        <div class="card-content font-30 bold-800 text-center">Share, play, talk and speak with your community</div>
    </div>
</template>

<script>
export default {
    props: {
        image: String,
        title: String,
        description: String
    }
}
</script>

<style lang="scss">
.main-section-card {
    width: 100%;
    height: 300px;
    border-radius: 20px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;

    .card-content {
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        transition: 250ms ease-in-out;

        &:hover {
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}
</style>