<template>
  <div class="main-container">
    <div class="title px-4 py-2">
      <div class="open-drawer-button" @click="showDrawer()"><v-icon class="pointer" icon="mdi-menu"></v-icon></div>
      <div class="search-bar m-auto">
        <input type="text" placeholder="search..." />
      </div>
      <div @click="showSocialDrawer()" class="open-socials-button"><v-icon class="pointer" icon="mdi-account"></v-icon></div>
    </div>
    <v-divider />
    <div class="main-content-wrapper">
      <div class="pa-4">
        <MainSectionCard />
      </div>
      <div>
        <FeaturedCardsList />
        <PopularCardsList />
      </div>
    </div>
  </div>
</template>

<script>
import MainSectionCard from "./MainSectionCard.vue";
import FeaturedCardsList from "./FeaturedCardsList.vue";
import PopularCardsList from "./PopularCardsList.vue";
import { useExplorerStore } from "../store";

export default {
  components: {
    MainSectionCard,
    FeaturedCardsList,
    PopularCardsList,
  },

  data: () => ({
    store: useExplorerStore(),
  }),

  methods: {
    showDrawer() {
      this.store.showDrawerMenu();
    },

    showSocialDrawer() {
      this.store.showSocialDrawerPannel();
    },
  },
};
</script>

<style lang="scss">
.main-container {
  color: white;
  height: 100vh;
  width: 100%;

  .main-content-wrapper {
    max-height: 100%;
    overflow: scroll !important;
    padding-bottom: 100px;
  }

  .v-divider {
    background-color: rgba(255, 255, 255, 0.2);
  }

  .title {
    height: 50px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(51 53 79);
    border-right: 1px solid rgba(255, 255, 255, 0.1);

    .search-bar {
      width: 50%;
      display: flex;
      justify-content: center;

      input {
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 5px;
        box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
        padding: 3px 0 !important;
        color: rgba(255, 255, 255, 0.7);
        font-size: 12px;
        width: 50%;
        text-align: center;
        transition: 250ms all ease-in-out;

        &:focus {
          outline: none;
          width: 100%;
        }
      }
    }
  }

  @media screen and (min-width: 1300px) {
    .title {
      .open-drawer-button {
        display: none;
      }
      .open-socials-button {
        display: none;
      }
    }
  }
}
</style>
