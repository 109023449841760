<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div id="globeViz"></div>
</template>

<script setup>
import { onMounted } from "vue";
import Globe from "globe.gl";

//Function
const apiResp = () => {
    const N = 30;
    const arcsData = [...Array(N).keys()].map(() => ({
        startLat: (Math.random() - 0.5) * 180,
        startLng: (Math.random() - 0.5) * 360,
        endLat: (Math.random() - 0.5) * 180,
        endLng: (Math.random() - 0.5) * 360,
        color: [['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)], ['red', 'white', 'blue', 'green'][Math.round(Math.random() * 3)]]
    }));


    const N_PATHS = 30;
    const MAX_POINTS_PER_LINE = 10000;
    const MAX_STEP_DEG = 1;
    const MAX_STEP_ALT = 0.015;
    const gData = [...Array(N_PATHS).keys()].map(() => {
        let lat = (Math.random() - 0.5) * 90;
        let lng = (Math.random() - 0.5) * 360;
        let alt = 0;

        return [[lat, lng, alt], ...[...Array(Math.round(Math.random() * MAX_POINTS_PER_LINE)).keys()].map(() => {
            lat += (Math.random() * 2 - 1) * MAX_STEP_DEG;
            lng += (Math.random() * 2 - 1) * MAX_STEP_DEG;
            alt += (Math.random() * 2 - 1) * MAX_STEP_ALT;
            alt = Math.max(0, alt);

            return [lat, lng, alt];
        })];
    });


    const world = Globe()
        .globeImageUrl('//unpkg.com/three-globe/example/img/earth-night.jpg')
        .backgroundColor(getComputedStyle(document.documentElement).getPropertyValue('--color-background'))
        .arcsData(arcsData)
        .arcColor('color')
        .arcDashLength(() => Math.random())
        .arcDashGap(() => Math.random())
        .arcDashAnimateTime(() => Math.random() * 4000 + 500)
        .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
        .pathsData(gData)
        .pathColor(() => ['rgba(0,0,255,0.6)', 'rgba(255,0,0,0.6)'])
        .pathDashLength(0.01)
        .pathDashGap(0.004)
        .pathDashAnimateTime(100000)
        .height(document.getElementById("right-drawer-container").offsetWidth)
        .width(document.getElementById("right-drawer-container").offsetWidth)(document.getElementById('globeViz'));

        // Auto-rotate
    world.controls().autoRotate = true;
    world.controls().autoRotateSpeed = 1;
};

onMounted(() => {
    apiResp();
});
</script>