<template>
  <div class="left-drawer-container">
    <div class="divider" />
    <div class="time">
      <span>{{ time }}</span>
    </div>
    <div class="date mb-4">{{ date }}</div>
    <div class="divider" />
    <div class="divider mt-4" />
    <div class="skills-container my-3">
      <div class="title flex justify-between">
        <span class="font-bold pb-3">Skills</span>
      </div>
      <div class="skills-list flex flex-column">
        <span>- HTML | CSS | JS</span>
        <span>- VueJS</span>
        <span>- React</span>
        <span>- NodeJS | ExpressJS</span>
        <span>- MongoDB | MySQL</span>
        <span>- EJS</span>
        <span>- Jquery</span>
        <span>- Flutter</span>
        <span>- Firebase</span>
      </div>
    </div>
    <div class="divider mb-5" />
    <div class="divider mt-4" />
    <div class="interests-container my-3">
      <div class="title flex justify-between">
        <span class="font-bold pb-3">Interests</span>
      </div>
      <div class="interests-list flex flex-column">
        <span>- IA</span>
        <span>- Machine Learning</span>
        <span>- Data Science</span>
        <span>- Web3</span>
      </div>
    </div>
    <div class="divider mb-5" />
  </div>
</template>

<script>
// eslint-disable-next-line no-undef
const moment = require("moment");
export default {
  name: "LeftDrawer",

  data: () => ({
    time: moment().format("HH:mm:ss"),
    date: moment().format("DD MMMM YYYY"),
  }),

  mounted() {
    setInterval(() => {
      this.time = moment().format("HH:mm:ss");
    }, 1000);
  },
};
</script>

<style lang="scss">
.left-drawer-container {
  height: 100%;
  width: 100%;

  .time {
    font-weight: 700;
    opacity: 0.8;
    font-size: 3.5rem;
    width: 100% !important;
    text-align: center;
  }

  .date {
    text-align: center;
  }

  .title {
    width: 100%;
  }
}
</style>
