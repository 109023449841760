<template>
    <div class="navigation-drawer-container">
        <div class="title bold-500 font-20 px-4 py-2">Explorer</div>
        <v-divider />
        <div class="navigation-list py-4 px-4">
            <div v-for="(item, index) of items" :key="index" @click="updateActiveItem(item)"
                :class="item.active ? ' item d-flex py-3 px-2 mb-2 active-item' : 'item d-flex py-3 px-2 mb-2'">
                <v-icon class="me-4">{{ item.icon }}</v-icon>
                <span>{{ item.title }}</span>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "NavigationDarawer",

    data: () => ({
        items: [
            {
                title: "Home",
                icon: "mdi-compass",
                active: true,
            },
            {
                title: "Music",
                icon: "mdi-music",
                active: false,
            },
            {
                title: "Gaming",
                icon: "mdi-gamepad",
                active: false,
            },
            {
                title: "Education",
                icon: "mdi-book",
                active: false,
            },
            {
                title: "Tech",
                icon: "mdi-desktop-classic",
                active: false,
            },
        ]
    }),

    methods: {
        updateActiveItem(item) {
            this.items = this.items.map(itm => {
                itm.active = false;

                if (itm.title === item.title) itm.active = true;

                return itm;
            })
        }
    }
}
</script>

<style lang="scss">
.navigation-drawer-container {
    background: rgb(129, 118, 160);
    background: linear-gradient(0deg, rgba(129, 118, 160, 1) 0%, rgba(91, 80, 146, 1) 100%);
    min-height: 100%;
    color: white;

    .v-divider {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .title {
        height: 50px;
    }

    .item {
        cursor: pointer;
        transition: 250ms all ease-in-out;
        border-radius: 10px;
        opacity: 0.7;

        &:hover {
            background-color: #322b60;
            opacity: 1;
        }
    }

    .active-item {
        background-color: #322b60;
        opacity: 1;
    }
}
</style>