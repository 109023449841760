<template>
    <div class="keyboard">
        <ul class="row row-0">
            <li class="key" id="esc">ESC</li>
            <li class="key" id="1">1</li>
            <li class="key" id="2">2</li>
            <li class="key" id="3">3</li>
            <li class="key" id="4">4</li>
            <li class="key" id="5">5</li>
            <li class="key" id="6">6</li>
            <li class="key" id="7">7</li>
            <li class="key" id="8">8</li>
            <li class="key" id="9">9</li>
            <li class="key" id="0">0</li>
            <li class="key">-</li>
            <li class="key">+</li>
            <li class="key" id="back">BACK</li>
        </ul>
        <ul class="row row-1">
            <li class="key" id="tab">TAB</li>
            <li class="key" id="Q">Q</li>
            <li class="key" id="W">W</li>
            <li class="key" id="E">E</li>
            <li class="key" id="R">R</li>
            <li class="key" id="T">T</li>
            <li class="key" id="Y">Y</li>
            <li class="key" id="U">U</li>
            <li class="key" id="I">I</li>
            <li class="key" id="O">O</li>
            <li class="key" id="P">P</li>
            <li class="key">[</li>
            <li class="key">]</li>
            <li class="key">\</li>
        </ul>
        <ul class="row row-2">
            <li class="key" id="caps">CAPS</li>
            <li class="key" id="A">A</li>
            <li class="key" id="S">S</li>
            <li class="key" id="D">D</li>
            <li class="key" id="F">F</li>
            <li class="key" id="G">G</li>
            <li class="key" id="H">H</li>
            <li class="key" id="J">J</li>
            <li class="key" id="K">K</li>
            <li class="key" id="L">L</li>
            <li class="key" id=":">:</li>
            <li class="key">''</li>
            <li class="key" id="enter">ENTER</li>
        </ul>
        <ul class="row row-3">
            <li class="key" id="left-shift">SHIFT</li>
            <li class="key" id="Z">Z</li>
            <li class="key" id="X">X</li>
            <li class="key" id="C">C</li>
            <li class="key" id="V">V</li>
            <li class="key" id="B">B</li>
            <li class="key" id="N">N</li>
            <li class="key" id="M">M</li>
            <li class="key" id=",">,</li>
            <li class="key" id=".">.</li>
            <li class="key" id=";">;</li>
            <li class="key" id="right-shift">SHIFT</li>
        </ul>
    </div>
</template>

<script>
const timestamps = [];

timestamps.unshift(getTimestamp());

function getTimestamp() {
    return Math.floor(Date.now() / 1000)
}

document.addEventListener("keyup", event => {
    const keyPressed = String.fromCharCode(event.keyCode);
    const keyElement = document.getElementById(keyPressed);
    const highlightedKey = document.querySelector(".selected");

    if (keyElement) {
        keyElement.classList.add("hit")
        keyElement.addEventListener('animationend', () => {
            keyElement.classList.remove("hit")
        })
    }

    if (keyPressed === highlightedKey?.innerHTML) {
        timestamps.unshift(getTimestamp());
        highlightedKey?.classList.remove("selected");
    }
});

export default {
    name: "KeyboardVue",
}
</script>

<style>
.keyboard {
    display: flex;
    align-items: center;

    flex-direction: column;
}

.row {
    list-style: none;
    display: flex;
}

li {
    height: 3em;
    width: 3em;
    color: rgba(0, 0, 0, 0.7);
    border-radius: 0.4em;
    line-height: 3em;
    letter-spacing: 1px;
    margin: 0.4em;
    transition: 0.3s;
    text-align: center;
    font-size: 1em;
}

#tab {
    width: 5em;
}

#caps {
    width: 6em;
}

#left-shift {
    width: 8em;
}

#enter {
    width: 6em;
}

#right-shift {
    width: 8em;
}

#back {
    width: 5em;
}

.key {
    border: 1px solid var(--color-items);
    color: var(--color-items);
}

.key.selected {
    color: rgb(0, 0, 0);
}

.hit {
    -webkit-animation: hit 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: hit 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    background-color: var(--color-items);
    color: var(--color-background);
}

@-webkit-keyframes hit {
    0% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes hit {
    0% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes vibrate-1 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }

    20% {
        -webkit-transform: translate(-2px, 2px);
        transform: translate(-2px, 2px);
    }

    40% {
        -webkit-transform: translate(-2px, -2px);
        transform: translate(-2px, -2px);
    }

    60% {
        -webkit-transform: translate(2px, 2px);
        transform: translate(2px, 2px);
    }

    80% {
        -webkit-transform: translate(2px, -2px);
        transform: translate(2px, -2px);
    }

    100% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}
</style>