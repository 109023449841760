<template>
    <div class="top-pane-container">
        <div class="left bottom-border flex flex-row justify-between pt-1 px-2">
            <span>Pannel</span>
            <span>System</span>
        </div>
        <div class="center bottom-border flex flex-row justify-between pt-1 px-2">
            <span>Terminal</span>
            <span>Main shell</span>
        </div>
        <div class="right bottom-border flex flex-row justify-between pt-1 px-2">
            <span>Pannel</span>
            <span>Network</span>
        </div>
    </div>
</template>

<style lang="scss">
.top-pane-container {
    font-size: 12px;
    font-weight: 700;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 30px;
    grid-template-areas:
        "left center right";
    height: 100%;
    width: 100%;
    grid-column-gap: 10px;
}
</style>