<template>
    <div class="right-drawer-container" id="right-drawer-container">
        <div class="divider" />
        <Glob/>
        <div class="divider" />
    </div>
</template>

<script>
import Glob from "./Glob.vue";
export default {
    components: {
        Glob
    }
}
</script>

<style lang="scss">
.right-drawer-container {
    height: 100%;
    width: 100%;

    #globeViz {
        max-width: 100%;
    }
}
</style>