<template>
    <div class="primary-drawer-container">
        <div class="title font-20 px-4 py-2">AG</div>
        <v-divider />
        <div class="icons-container d-flex flex-column justify-center align-center">
            <div v-for="(image, index) of icons" :key="index"
                class="channel-image-container d-flex flex-column align-center pointer">
                <img :src="require(`../assets/${image.icon}`)" alt="" class="channel-img">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PrimaryDrawer",
    data() {
        return {
            icons: [
                { icon: "crow-dynamic-premium.png" },
                { icon: "fire-dynamic-premium.png" },
                { icon: "minecraft-dynamic-premium.png" },
                { icon: "rocket-dynamic-premium.png" },
                { icon: "plus.png" }
            ]
        }
    }
} 
</script>

<style lang="scss">
@import url('https://fonts.cdnfonts.com/css/do-futuristic');

.primary-drawer-container {
    background-color: #564f88;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    height: 100%;
    color: #fff;

    .v-divider {
        background-color: rgba(255, 255, 255, 0.2);
    }

    .title {
        height: 50px;
        text-align: center;
        color: white;
        font-family: 'DO Futuristic', sans-serif;
        font-weight: 900;
    }

    .icons-container {
        padding-top: 20px;
        background-color: #564f88;
        width: 100%;

        .channel-image-container {
            height: 50px;
            width: 50px;
            padding: 10px;
            margin-bottom: 20px;
            border-radius: 50%;
            transition: 250ms all ease-in-out;
            background-image: linear-gradient(83.2deg, rgba(150, 93, 233, 1) 10.8%, rgba(99, 88, 238, 1) 94.3%);
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
            position: relative;

            img {
                width: 100%;
                z-index: 1;
            }

            &:hover {
                outline: 2px solid rgba(255, 255, 255, 0.3);
                height: 55px;
                width: 55px;
            }
        }

    }
}
</style>