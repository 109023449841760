<template>
  <div class="neo-dashboard-project-container">
    <div class="glob-container">
      <Glob />
    </div>
    <div class="neo-dashboard-content">
      <div class="left-side"><LeftSide /></div>
      <div class="central-side"></div>
      <div class="right-side"><RightSide /></div>
    </div>
  </div>
</template>

<script>
import Glob from "./components/Glob.vue";
import LeftSide from "./components/LeftSide.vue";
import RightSide from "./components/RightSide.vue";

export default {
  name: "NeoDashboard",
  components: {
    Glob,
    LeftSide,
    RightSide,
  },
};
</script>

<style lang="scss">
.neo-dashboard-project-container {
  background-color: #000000;
  color: white;
  height: 100vh;
  width: 100vw;
  position: relative;
  z-index: 0;
  font-family: "Orbitron", sans-serif;

  .glob-container {
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    z-index: 1;
    position: absolute;
  }

  .neo-dashboard-content {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: grid;
    grid-template-columns: 2fr 3fr 2fr;
  }

  .left-side {
    height: 100%;
  }

  .divider {
    height: 1px;
    width: 100%;
    position: relative;
    border-top: 0.5px solid rgb(26, 207, 255);
    opacity: 0.3;

    &::before {
      content: "";
      position: absolute;
      top: -3px;
      right: 0;
      width: 0.5px;
      height: 6px;
      background-color: rgb(26, 207, 255);
    }

    &::after {
      content: "";
      position: absolute;
      top: -3px;
      left: 0;
      width: 0.5px;
      height: 6px;
      background-color: rgb(26, 207, 255);
    }
  }
  
  .styled-container {
    background-color: rgba(26, 207, 255, 0.05);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      width: 120%;
      left: -10px;
      height: 80%;
      background-color: rgba(26, 207, 255, 0.05);
    }
  }
}
</style>
