import { createRouter, createWebHistory } from "vue-router";

import Portfolio from "@/views/Portfolio";
import ExplorerProject from "@/components/projects/explorer/view/Explorer";
import WebTerminal from "@/components/projects/web-terminal/WebTerminal";
import NeoDashboard from "@/components/projects/neo-dashboard/NeoDashboard";

const routes = [
  {
    path: "/",
    name: "portfolio",
    component: Portfolio,
  },
  {
    path: "/projects/explorer",
    name: "project-explorer",
    component: ExplorerProject,
  },
  {
    path: "/projects/web-terminal",
    name: "web-termianl",
    component: WebTerminal,
  },
  {
    path: "/projects/neo-dashboard",
    name: "neo-dashboard",
    component: NeoDashboard,
  },
  { path: "/:pathMatch(.*)*", name: "not-found", component: Portfolio },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
