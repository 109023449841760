<template>
	<div>
		<div :class="['no-scroll-overlay flex justify-center align-center text-white font-bold text-4xl', hideOverlay ? 'hide-overlay' : '']">{{ currentHello }}</div>
		<div id="smooth-wrapper">
			<div id="smooth-content">
				<TopBar />
				<Hero />
				<About />
				<Works /> 
        <DiscoverExplorer />
        <div class="w-100 h-52"></div>
				<FooterComponent /> 
			</div>  
		</div>
	</div>
</template>

<script>
import TopBar from "@/components/top-bar/TopBar.vue";
import FooterComponent from "@/components/footer/Footer.vue";
import Hero from "@/components/hero/Hero.vue";
import Works from "@/components/works/Works.vue";
import About from "@/components/about/About.vue";
import DiscoverExplorer from "@/components/discover-explorer/DiscoverExplorer.vue";

export default {
	name: "PortfolioView",

	components: {
		FooterComponent, 
		TopBar,
		Hero,
		Works,
		About,
    DiscoverExplorer,
	}, 

  data: () => ({
    hideOverlay: false,
    currentHello: "Bonjour",
    hellos: ["Bonjour", "Hello", "Hola", "Ciao", "Привет", "^_^"],
  }),

  mounted() {
    setInterval(() => {
      const index = this.hellos.indexOf(this.currentHello);
      if (index === this.hellos.length - 1) {
        this.hideOverlay = true;
        return;
      } 
      this.currentHello = this.hellos[index + 1];
    }, 500);
  },
};
</script>

<style lang="scss">
$transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
.no-scroll-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  z-index: 900;
  background-color: #000;
  transition: $transition;
  cursor: progress;
}

.hide-overlay {
  top: -100vh;
  border-radius: 50%;
}
</style>
