<template>
  <div>
    <div class="right-side-container p-4">
      <div class="divider" />
      <div class="date-container styled-container flex flex-col justify-center text-right p-4">
        <span class="text-xl font-bold">{{ time }}</span>
        <span>{{ date }}</span>
      </div>
      <div class="divider" />
    </div>
    <div class="divider" />
  </div>
</template>

<script>
const moment = require("moment");

export default {
  data: () => ({
    time: moment().format("HH:mm:ss"),
    date: moment().format("DD MMMM YYYY"),
  }),

  mounted() {
    setInterval(() => {
      this.time = moment().format("HH:mm:ss");
    }, 1000);
  },
};
</script>
