<template>
  <div class="central-pane-container">
    <div class="console-output px-4 flex flex-col-reverse pb-2">
      <div class="output" v-for="(output, idx) of consoleOutputs" :key="idx">
        {{ output  }}
      </div>
    </div>
    <div class="input-container pl-4">
      <span class="tinitiator mr-2">|></span>
      <input v-model="commandInput" maxlength="50" placeholder="/help" type="text" @keyup.enter="handleCommandInput" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftDrawer",

  data: () => ({
    commandInput: "",
    showCommandsSuggestions: false,
    consoleOutputs: [
    ],
  }),

  methods: {
    handleCommandInput() {
      this.consoleOutputs.push(this.commandInput);
      this.commandInput = "";
    },
  },

  watch: {
    commandInput(val) {
      if(val.includes("/")) {
        this.showCommandsSuggestions = true;
      }
    }
  }
};
</script>

<style lang="scss">
.central-pane-container {
  border: 0.5px solid var(--color-items);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .console-output {
    height: 100%;
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .input-container {
    border-top: 0.5px solid var(--color-items);
    display: flex;
    align-items: center;
    position: relative;


    input {
      width: 100%;
      height: 50px;
      justify-self: flex-end;
      background-color: transparent;
      padding: 0 10px;
    }

    input:focus {
      outline: none;
    }
  }
}
</style>
