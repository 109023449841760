<template>
  <div class="home-top-bar-container">
    <v-container>
      <div class="flex justify-between flex-row">
        <div class="flex justify-start text-2xl home-title">
          <div class="credit me-1"><span>©</span></div>
          <div class="overflow-hidden relative pointer" @click="goHome">
            <span class="code-by">Code by </span>
            <div class="top-left-name">
              <span class="alin mx-1">Alin</span>
              <span class="gavriliuc">Gavriliuc</span>
            </div>
          </div>
        </div>
        <div class="justify-center align-center hidden md:flex">
          <p class="pointer mx-2 menu-item" @click="scrollToSection('#about')">About</p>
          <p class="pointer mx-2 menu-item" @click="scrollToSection('#works')">Work</p>
          <p class="pointer ms-2 menu-item" @click="mailto">Contact</p>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "TopBar",

  methods: {
    goHome() {
      this.$router.push("/");
    },

    mailto() {
      window.open("mailto:alingavriliuc@hotmail.com");
    },

    scrollToSection(id) {
      gsap.to(window, { duration: 1, scrollTo: id });
    }
  },
};
</script>

<style lang="scss">
$transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);

.home-top-bar-container {
  .home-title {
    height: 40px;
    overflow: hidden;

    .top-left-name,
    .code-by {
      transition: $transition;
      transform: translateX(0);
      position: relative;
      display: inline-block;
    }

    .gavriliuc {
      opacity: 0;
      transition: $transition;
    }

    &:hover {
      .code-by,
      .top-left-name {
        transform: translateX(-3.7em);
      }

      .gavriliuc {
        opacity: 1;
        transition: $transition;
      }
    }
  }

  .menu-item {
    position: relative;
    opacity: 0.8;
    font-weight: normal;
    transition: opacity 250ms cubic-bezier(0.7, 0, 0.3, 1);

    &:hover {
      opacity: 1;
    }
  }
}
</style>
