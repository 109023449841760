<template>
  <v-container id="about" class="mb-10">
    <v-row>
      <v-col cols="12" lg="4">
        <h3 class="font-bold text-4xl">Who am I ?</h3>
      </v-col>
      <v-col cols="12" lg="6">
        <span>
          Experienced web and mobile developer with a passion for crafting intuitive and engaging user experiences.
          <br /><br />
          Adept at working in fast-paced, collaborative environments, and leveraging agile methodologies to drive successful project outcomes. A lifelong learner, I stay ahead of industry trends and
          continuously seek out new technologies to incorporate into my work. Excited to bring my expertise and drive for excellence to new opportunities in the dynamic field of web and mobile
          development.
          <br /><br />
          Proficient in a variety of programming languages including JavaScript, VueJS, ReactJs, NodeJS and Flutter.
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AboutVue",
};
</script>
