<template>
	<div class="px-4 py-2">
		<div class="d-flex justify-space-between align-end">
			<span class="font-24 bold-600">Popular</span>
			<span class="me-4 font-16 bold-600 opacity-70 pointer"> see all </span>
		</div>
		<div class="popular-card-list-container pt-2 pointer">
			<div v-for="(item, incex) of items" :key="incex" class="card" :style="`background-image:url('${item.image}')`">
				<div class="cart-content d-flex flex-column justify-space-between pa-4">
					<div class="account" :style="`background-image: url(${item.profile})`"></div>
					<div class="card-title font-20 bold-500">{{ item.title }}</div>
					<div class="card-description font-16 opacity-60 ps-4">{{ item.description }}</div>
					<div class="discover-button font-14">discover <v-icon size="x-small">mdi-arrow-right </v-icon></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "CardsList",

	data: () => ({
		items: [
			{
				title: "The Best FUTURE Ever!",
				description: "The Truth Is You Are Not The Only Person Concerned About FUTURE",
				image: "https://images.unsplash.com/photo-1601042879364-f3947d3f9c16?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y3liZXJwdW5rfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
				profile: "https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1600",
			},
			{
				title: "A Quick Way To FUTURE",
				description: "Boost Your FUTURE With These Tips",
				image: "https://images.unsplash.com/photo-1563089145-599997674d42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8Y3liZXJwdW5rfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
				profile: "https://images.unsplash.com/photo-1500389723459-ca24a5564899?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTh8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
			},
			{
				title: "To FUTURE At Any Age",
				description: "Double Your Profit With These 5 Tips on FUTURE",
				image: "https://images.unsplash.com/photo-1575365717666-1a84be3fd104?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGN5YmVycHVua3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
				profile: "https://images.pexels.com/photos/91227/pexels-photo-91227.jpeg?auto=compress&cs=tinysrgb&w=1600",
			},
			{
				title: "Save Money with FUTURE",
				description: "14 Days To A Better FUTURE",
				image: "https://images.unsplash.com/photo-1533972751724-9135a8410a4c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGN5YmVycHVua3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
				profile: "https://images.unsplash.com/photo-1476903930099-d0ddfec9a475?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MjR8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
			},
			{
				title: "The Lazy Man's Guide ",
				description: "Why FUTURE Is The Only Skill You Really Need",
				image: "https://images.unsplash.com/photo-1563863251222-11d3e3bd3b62?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fGN5YmVycHVua3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
				profile: "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MzJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
			},
			{
				title: "FUTURE? It's Easy",
				description: "Answered: Your Most Burning Questions About FUTURE",
				image: "https://images.unsplash.com/photo-1606542758304-820b04394ac2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fGN5YmVycHVua3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60",
				profile: "https://images.unsplash.com/photo-1530107973768-581951e62d34?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MzV8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60",
			},
		],
	}),
};
</script>

<style lang="scss">
.popular-card-list-container {
	display: flex;
	gap: 20px;
	width: 100%;
	overflow-x: scroll;
	padding-bottom: 20px;

	.card {
		min-width: 300px;
		min-height: 200px;
		border-radius: 20px;
		background-color: #fff;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		background-position: center;
		background-size: cover;
		overflow: hidden;
		display: flex;
		justify-content: flex-end;
		transition: 250ms all ease-in-out;
		position: relative;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

		.cart-content {
			width: 70%;
			height: 100%;
			padding-left: 50px;
			text-align: end;
			background: rgba(255, 255, 255, 0.25);
			box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
			backdrop-filter: blur(6px);
			-webkit-backdrop-filter: blur(6px);
			border: 1px solid rgba(255, 255, 255, 0.18);
			transition: 250ms all ease-in-out;
			position: relative;

			.account {
				position: absolute;
				width: 50px;
				height: 50px;
				border-radius: 10px;
				left: -25px;
				top: 70px;
				overflow: hidden;
				box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(255, 255, 255, 0.5) 0px 0px 0px 3px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		.discover-button {
			opacity: 0;
			transition: 250ms all ease-in-out;
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			transition: 250ms all ease-in-out;
		}

		&:hover {
			.discover-button {
				opacity: 0.5;
			}

			&::before {
				background-color: rgba(0, 0, 0, 0.6);
			}
		}
	}
}
</style>
