<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="neoGlobeViz" style="min-height: 100vh"></div>
</template>

<script setup>
import { onMounted } from "vue";
import Globe from "globe.gl";

//Function
const drawGlob = () => {
  const world = Globe()(document.getElementById("neoGlobeViz"));

  const N = 20;
  const arcsData = [...Array(N).keys()].map(() => ({
    startLat: (Math.random() - 0.5) * 180,
    startLng: (Math.random() - 0.5) * 360,
    endLat: (Math.random() - 0.5) * 180,
    endLng: (Math.random() - 0.5) * 360,
    color: [["red", "white", "blue", "green"][Math.round(Math.random() * 3)], ["red", "white", "blue", "green"][Math.round(Math.random() * 3)]],
  }));

  const N_PATHS = 10;
  const MAX_POINTS_PER_LINE = 10000;
  const MAX_STEP_DEG = 1;
  const MAX_STEP_ALT = 0.1;
  const gData = [...Array(N_PATHS).keys()].map(() => {
    let lat = (Math.random() - 0.5) * 90;
    let lng = (Math.random() - 0.5) * 360;
    let alt = 0;

    return [
      [lat, lng, alt],
      ...[...Array(Math.round(Math.random() * MAX_POINTS_PER_LINE)).keys()].map(() => {
        lat += (Math.random() * 2 - 1) * MAX_STEP_DEG;
        lng += (Math.random() * 2 - 1) * MAX_STEP_DEG;
        alt += (Math.random() * 2 - 1) * MAX_STEP_ALT;
        alt = Math.max(0, alt);

        return [lat, lng, alt];
      }),
    ];
  });

  // Auto-rotate
  world.globeImageUrl("//unpkg.com/three-globe/example/img/earth-night.jpg");
  world.backgroundImageUrl("//unpkg.com/three-globe/example/img/night-sky.png");
  world.controls().autoRotate = true;
  world.controls().autoRotateSpeed = 0.5;
  world.pointOfView({ altitude: 5 }, 5000);
  world.bumpImageUrl("//unpkg.com/three-globe/example/img/earth-topology.png");
  world.pathsData(gData);
  world.pathColor(() => ["rgba(0,0,255,0.6)", "rgba(255,0,0,0.6)"]);
  world.pathDashLength(0.01);
  world.pathDashGap(0.004);
  world.pathDashAnimateTime(100000);
  world.arcsData(arcsData);
  world.arcColor("color");
  world.arcDashLength(() => Math.random());
  world.arcDashGap(() => Math.random());
  world.arcDashAnimateTime(() => Math.random() * 4000 + 500);
};

onMounted(() => {
  drawGlob();
});
</script>
