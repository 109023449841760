<template>
  <div class="explorer-project-container">
    <div class="content">
      <div v-if="(store.showNavDrawer === true || store.showSocialDrawer === true)" @click="hideDrawers()" class="dismis-drawers"></div>
      <div :class="['drawers d-flex flex-column', store.showNavDrawer ? 'show-mobile-nav-drawer' : '']">
        <div class="d-flex fit-height">
          <div class="primary-nav-drawer">
            <PrimaryDrawer />
          </div>
          <div class="secondary-nav-drawer">
            <NavigationDrawer />
          </div>
        </div>
        <div class="bottom-controller">
          <NavDrawerBottomController />
        </div>
      </div>
      <div class="main-content">
        <MainSection />
      </div>
      <div :class="['right-pannel', store.showSocialDrawer === true ? 'show-mobile-social-driver' : '']">
        <RightPannel />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationDrawer from "../components/NavigationDrawer.vue";
import MainSection from "../components/MainSection.vue";
import RightPannel from "../components/RightPannel.vue";
import PrimaryDrawer from "../components/PrimaryDrawer.vue";
import NavDrawerBottomController from "../components/NavDrawerBottomController.vue";
import "../assets/styles/responsive.scss";
import { useExplorerStore } from "../store";

export default {
  name: "ExplorerProject",

  data: () => ({
    store: useExplorerStore(),
  }),

  components: {
    NavigationDrawer,
    MainSection,
    RightPannel,
    PrimaryDrawer,
    NavDrawerBottomController,
  },

  methods: {
    hideDrawers() {
      this.store.hideDrawerMenu();
      this.store.hideSocialDrawerPannel();
    },
  },
};
</script>

<style lang="scss">
.pointer {
  cursor: pointer;
}

.dismis-drawers {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 9;
}

.explorer-project-container {
  width: 100vw !important;
  height: 100vh !important;
  background-color: #876ed3;
  overflow: hidden !important;

  .content {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    position: relative;
    display: flex;
  }

  .bottom-controller {
    height: 100%;
    min-height: 100px;
  }

  .primary-nav-drawer {
    min-width: 100px;
    height: 85vh;
  }

  .secondary-nav-drawer {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-backdrop-filter: blur(4px);
  }

  .main-content {
    max-width: 100% !important;
    max-height: 100vh !important;
    overflow: hidden !important;
    background-color: rgb(33 35 69);
  }

  .drawers {
    min-width: 300px;
  }

  .right-pannel {
    min-width: 300px !important;
    background-color: rgb(51 53 79);
  }
}
</style>
