import { defineStore } from "pinia";

export const useExplorerStore = defineStore("explorer", {
  state: () => ({
    showNavDrawer: false,
    showSocialDrawer: false,
  }),

  actions: {
    showDrawerMenu() {
      this.showNavDrawer = true;
    },

    hideDrawerMenu() {
        this.showNavDrawer = false;
    },

    showSocialDrawerPannel() {
        this.showSocialDrawer = true;
    },

    hideSocialDrawerPannel() {
        this.showSocialDrawer = false;
    },
  },
});
