<template>
    <div class="px-4 py-4">
        <div class="d-flex justify-space-between align-end">
            <span class="font-24 bold-600">Featured</span>
            <span class="me-4 font-16 bold-600 opacity-70 pointer">
                see all
            </span>
        </div>
        <div class="card-list-container pt-2 pointer">
            <div v-for="(item, incex) of items" :key="incex" class="card"
                :style="`background-image:url('${item.image}')`">
                <div class="cart-content d-flex flex-column justify-space-between pa-4">
                    <div class="account" :style="`background-image:url(${item.profile})`"></div>
                    <div class="card-title font-20 bold-500">{{ item.title }}</div>
                    <div class="card-description font-16 opacity-60">{{ item.description }}</div>
                    <div class="discover-button font-14">discover <v-icon size="x-small">mdi-arrow-right
                        </v-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardsList",

    data: () => ({
        items: [
            {
                title: "Everything You Wanted",
                description: "Everything You Wanted to Know About FUTURE",
                image: "https://wallpaperaccess.com/full/4553077.jpg",
                profile: "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            },
            {
                title: "Picture Your FUTURE.",
                description: "Create Better FUTURE With The Help Of Your Dog",
                image: "https://cdn.pixabay.com/photo/2016/06/17/06/04/background-1462755__480.jpg",
                profile: "https://images.unsplash.com/photo-1544168190-79c17527004f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=60"
            },
            {
                title: "Believe In Your FUTURE",
                description: "How To Win Clients And Influence Markets with FUTURE",
                image: "https://cdn.pixabay.com/photo/2019/05/29/16/00/retro-4237850__480.jpg",
                profile: "https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8M3x8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=60"
            },
            {
                title: "No More Mistakes",
                description: "At Last, The Secret To FUTURE Is Revealed",
                image: "https://cdn.pixabay.com/photo/2018/02/17/11/30/abstract-3159733__480.jpg",
                profile: "https://images.unsplash.com/photo-1504199367641-aba8151af406?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8OXx8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=60"
            },
            {
                title: "11 Methods Of FUTURE ",
                description: "FUTURE: An Incredibly Easy Method That Works For All",
                image: "https://cdn.pixabay.com/photo/2018/03/05/18/14/perspective-3201397__480.jpg",
                profile: "https://images.unsplash.com/photo-1542596768-5d1d21f1cf98?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8N3x8fGVufDB8fHx8&auto=format&fit=crop&w=900&q=60"
            },
            {
                title: "7 Rules About FUTURE ",
                description: "Avoid The Top 10 FUTURE Mistakes",
                image: "https://cdn.pixabay.com/photo/2019/10/12/15/33/futuristic-4544119__480.jpg",
                profile: "https://images.unsplash.com/photo-1499155286265-79a9dc9c6380?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTJ8fHxlbnwwfHx8fA%3D%3D&auto=format&fit=crop&w=900&q=60"
            },
        ]
    }),
}
</script>

<style lang="scss">
.card-list-container {
    display: flex;
    gap: 20px;
    max-width: 100%;
    overflow-x: scroll;
    padding-bottom: 20px;


    .card {
        min-width: 300px;
        min-height: 200px;
        border-radius: 20px;
        background-color: #fff;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
        background-position: center;
        background-size: cover;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        transition: 250ms all ease-in-out;
        position: relative;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


        .cart-content {
            width: 100%;
            height: 50%;
            text-align: start;
            background: rgba(255, 255, 255, 0.25);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            backdrop-filter: blur(6px);
            -webkit-backdrop-filter: blur(6px);
            border: 1px solid rgba(255, 255, 255, 0.18);
            transition: 250ms all ease-in-out;
            position: relative;

            .account {
                position: absolute;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                right: 10px;
                aspect-ratio: 1;
                top: -35px;
                overflow: hidden;
                box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgba(255, 255, 255, 0.5) 0px 0px 0px 3px;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }
        }

        .discover-button {
            opacity: 0;
            transition: 250ms all ease-in-out;
        }

        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            transition: 250ms all ease-in-out;
        }

        &:hover {
            .cart-content {
                height: 60%;
            }

            .discover-button {
                opacity: 0.5;
            }

            &::before {
                background-color: rgba(0, 0, 0, 0.6);
            }
        }
    }
}
</style>

