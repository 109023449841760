<template>
  <v-container>
    <v-row>
      <v-col cols="7"> &copy;Alin Gavriliuc</v-col>
      <v-col class="flex justify-end"><a href="https://www.linkedin.com/in/alin-gavriliuc/" target="_blank">LinkedIn</a> </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
