<template>
  <div class="web-terminal-container px-4">
    <div class="left-drawer py-4"><LeftDrawerVue /></div>
    <div class="top-pane"><TopPaneVue /></div>
    <div class="central-pane p-4"><CentralPaneVue /></div>
    <div class="bottom-pane"><BottomPane /></div>
    <div class="right-drawer py-4"><RightDrawerVue /></div>
  </div>
</template>

<script>
import TopPaneVue from "./components/TopPane.vue";
import CentralPaneVue from "./components/CentralPane.vue";
import LeftDrawerVue from "./components/LeftDrawer.vue";
import RightDrawerVue from "./components/RightDrawer.vue";
import BottomPane from "./components/BottomPane.vue";

import "./assets/styles/_variables.css";

export default {
  name: "HomeView",

  components: {
    TopPaneVue,
    CentralPaneVue,
    LeftDrawerVue,
    RightDrawerVue,
    BottomPane,
  },
};
</script>

<style lang="scss">
.divider {
    height: 1px;
    width: 100%;
    position: relative;
    border-top: 0.5px solid var(--color-items);
    opacity: 0.6;

    &::before {
        content: "";
        position: absolute;
        top: -3px;
        right: 0;
        width: 0.5px;
        height: 6px;
        background-color: var(--color-items);
    }

    &::after {
        content: "";
        position: absolute;
        top: -3px;
        left: 0;
        width: 0.5px;
        height: 6px;
        background-color: var(--color-items);
    }
}

.bottom-border {
    position: relative;
    border-bottom: 0.5px solid var(--color-items);
    opacity: 0.6;

    &::before {
        content: "";
        position: absolute;
        bottom: -3px;
        right: 0;
        width: 0.5px;
        height: 6px;
        background-color: var(--color-items);
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 0.5px;
        height: 6px;
        background-color: var(--color-items);
    }
}

.top-border {
    position: relative;
    border-top: 0.5px solid var(--color-items);
    opacity: 0.6;

    &::before {
        content: "";
        position: absolute;
        top: -3px;
        right: 0;
        width: 0.5px;
        height: 6px;
        background-color: var(--color-items);
    }

    &::after {
        content: "";
        position: absolute;
        top: -3px;
        left: 0;
        width: 0.5px;
        height: 6px;
        background-color: var(--color-items);
    }
}

.web-terminal-container {
  background-color: var(--color-background);
  font-family: 'Orbitron', sans-serif;
  color: var(--color-items);
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-rows: 30px 3fr 250px;
  grid-template-areas:
    "top-pane top-pane top-pane"
    "left-drawer central-pane right-drawer"
    "bottom-pane bottom-pane bottom-pane";
  height: 100vh;
  width: 100vw;
}

.left-drawer {
  grid-area: left-drawer;
}

.central-pane {
  grid-area: central-pane;
  overflow: hidden;
}

.right-drawer {
  grid-area: right-drawer;
}

.bottom-pane {
  grid-area: bottom-pane;
}

.top-pane {
  grid-area: top-pane;
}
</style>
