<template>
	<div>
		<div class="left-side-container pe-5 py-4">
			<div class="divider" />
			<div class="top-profilec-wrapper styled-container pl-5 flex">
				<v-avatar :image="require('@/assets/images/profile.png')"></v-avatar>
				<h4 class="ps-5">Alin Gavriliuc</h4>
			</div>
			<div class="divider" />
		</div>

		<div class="divider" />
		<div class="styled-container flex justify-between followed-cryptos-list p-2 pl-5 mb-2">qsdqsd</div>
		<div class="divider" />
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},

	methods: {},

	mounted() {},
};
</script>

<style lang="scss">
.left-side-container {
	.top-profilec-wrapper {
		padding: 10px;
		display: flex;
		align-items: center;
		height: 80px;
		width: auto;
		z-index: 1;

		img {
			height: 50px;
			width: 50px;
			border-radius: 10% 0 10% 0;
		}
	}

	.line-chart {
		max-height: 100px !important;
	}
}

.followed-cryptos-list {
	img {
		border-radius: 50%;
		overflow: hidden;
		height: 30px;
		width: 30px;
	}
}
</style>
