<template>
  <div class="nav-container d-flex flex-column">
    <img src="../assets/Sound.svg" alt="" srcset="../assets/Sound.svg" />
    <div class="speacker d-flex justify-center align-center px-4">
      <!-- <div class="profile-image px-4 mx-4"></div> -->
      <div class="controls d-flex align-center justify-between px-4 py-2">
        <div class="d-flex justify-center align-center">
          <v-icon class="icon" size="small">mdi-volume-high</v-icon>
        </div>
        <div class="d-flex justify-center align-center">
          <v-icon class="icon" size="small">mdi-microphone</v-icon>
        </div>
        <div class="d-flex justify-center align-center">
          <v-icon class="icon" size="small">mdi-skip-previous</v-icon>
        </div>
        <div class="d-flex justify-center align-center">
          <v-icon class="icon" size="small">mdi-skip-next</v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.nav-container {
  min-height: 100%;
  background-color: #212345;
  border-right: 1px solid rgba(255, 255, 255, 0.1);

  img {
    width: 100%;
    height: 70px;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;
  }

  .speacker {
    height: 80px;
  }

  .profile-image {
    background-image: url("@/assets/images/profile_photo.jpg");
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-position: center;
    background-size: 110%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  .controls {
    width: 100%;
    color: white;
  }

  .icon {
    opacity: 0.7;
    transition: 250ms all ease-in-out;
    cursor: pointer;
    position: relative;
    z-index: 1 !important;
    font-size: 24px;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.2);
      transition: 250ms all ease-in-out;
      z-index: -1;
      opacity: 0;
    }

    &:hover {
      border-radius: 30px;

      &::after {
        width: 180%;
        height: 180%;
        opacity: 1;
      }
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}
</style>
