<template>
  <v-container class="works-container-wrapper" id="works">
    <h3 class="font-bold text-4xl pb-7">Recent works</h3>
    <div class="works-container" id="works-container">
      <div v-for="(work, idx) of works" :key="idx" class="work-card opacity-80 shadow-sm d-flex gap-5 my-1 border rounded-md p-2">
        <div class="relative w-4/12 lg:w-60">
          <img :src="require(`@/assets/images/${work?.imageName}`)" alt="work-image" class="grayscale"/>
          <div class="company-logo absolute w-10 h-10 border rounded-lg bottom-1 right-1 overflow-hidden shadow-md">
            <img :src="require(`@/assets/images/companiesLogo/${work?.companyLogo}`)" alt="company-logo" class="z-10 h-100" />
          </div>
        </div>
        <div class="title font-bold flex justify-center align-center text-1xl md:text-2xl lg:text-3xl">{{ work.title }}</div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "MyWorks",

  components: {},

  data: () => ({
    works: [
      {
        imageName: "mdb-app-image.jpg",
        companyLogo: "mdb.jpg",
        companyName: "Freelance",
        title: "LES MAISONS DES BROCATEURS",
        description: "Mobile application for commerce and auctions between individuals",
      },
      {
        imageName: "pozzz-landing-page.jpg",
        companyLogo: "pozzz.jpg",
        companyName: "Genius Objects",
        title: "POZZZ (Landing page)",
        description: "Pozzz new app presentation landing page.",
      },
      {
        imageName: "itaf-image.jpg",
        companyLogo: "itaf.jpg",
        companyName: "ITAF TECHNOLOGIES",
        title: "ITAF TECHNOLOGIES",
        description: "Medial dashboard app used by more than 600 medical institus in france.",
      },
      {
        imageName: "eventz-image.jpg",
        companyLogo: "eventz.jpg",
        companyName: "Freelance",
        title: "EVENTZ MOBILE APP",
        description: "Mobile app to share eventz with other people around the world",
      },
      {
        imageName: "pozzz_dashboard_mockup.jpg",
        companyLogo: "pozzz.jpg",
        companyName: "Genius Objects",
        title: "POZZZ (Back office)",
        description: "Complex dashboard app that offers the possibility to supervise, analyse, and treate all key data.",
      },
    ],
  }),
};
</script>

<style lang="scss">
.works-container {
  height: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .work-card {
    transition: 0.5s ease-in-out;

    &:hover {
      img {
        filter: grayscale(0);
      }
      opacity: 1;
      background-color: rgba($color: #fff, $alpha: 0.1);
    }
  }
}
</style>
